import { useEffect, useRef } from 'react';
import { DataGrid, GridSortItem, useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import InlineEditableText from '../../../components/common/InlineEditableText';
import { CustomDataGridProps } from '../../../components/common/Table/CustomDataGridTypes';

export interface TimelineEntriesSectionProps extends CustomDataGridProps {
  section: any;
  timelineID: bigint;
  caseID: string;
  rows: any[];
  sortModel: GridSortItem[];
  handleSortChange: (sortField: GridSortItem[], sectionID: string) => void;
  updateIndexReportSectionName: (sectionId: string, newName: string) => void;
  areSectionsGrouped: boolean;
}

export default function TimelineEntriesTableSection({
  columns,
  section,
  timelineID,
  caseID,
  rows,
  sortModel,
  handleSortChange,
  updateIndexReportSectionName,
  ...rest
}: TimelineEntriesSectionProps) {
  const isInitialLoad = useRef(true);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    }
  }, []);

  return (
    <Box
      sx={{
        mt: '1rem',
        mb: '2rem',
      }}
    >
      <TimelineEntryTableSectionHeader
        onSubmitUpdatedSectionName={(newName) => updateIndexReportSectionName(section.id, newName)}
        sectionName={section?.name}
      />
      <DataGrid
        columns={columns ?? []}
        {...rest}
        rows={rows}
        apiRef={apiRef}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => handleSortChange(newSortModel, section.id)}
      />
    </Box>
  );
}

type TimelineEntryTableSectionHeaderProps = {
  onSubmitUpdatedSectionName: (newName: string) => void;
  sectionName: string;
};

function TimelineEntryTableSectionHeader({
  onSubmitUpdatedSectionName,
  sectionName,
}: TimelineEntryTableSectionHeaderProps) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        mx: '1rem',
      }}
    >
      <InlineEditableText
        value={sectionName ?? ''}
        onSubmit={onSubmitUpdatedSectionName}
        typographyProps={{
          fontSize: '12px',
          fontWeight: 600,
        }}
        boxProps={{
          sx: {
            backgroundColor: '#E5EDF5',
            borderRadius: '8px 8px 0 0 ',
          },
        }}
        typographyBoxProps={{
          sx: {
            borderRadius: '8px 8px 0 0 ',
            px: '0.5rem',
          },
        }}
        textFieldProps={{
          sx: {
            input: {
              fontSize: '12px',
              fontWeight: 600,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            paddingY: '0px',
            pl: '1rem',
          },
        }}
      />
    </Box>
  );
}
