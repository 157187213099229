import { useState, useMemo, useEffect, useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import {
  TextField,
  CircularProgress,
  Skeleton,
  InputAdornment,
  Typography,
  Button,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useQueryClient } from '@tanstack/react-query';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import MultiSelectDropdown from '../../../../components/common/HTML_components/MultiSelectDropdown/MultiSelectDropdown';
import useContentTypesAndSpecialities from '../../../Timeline/gql/useContentTypesAndSpecialities';
import useBulkUpdatePageTags from '../../../Timeline/gql/useBulkUpdatePageTags';
import EntitySelect from '../../../Timeline/EntitySelect';
import useEntities from '../../../Timeline/gql/useEntities';
import { isValidDate } from '../../../../library/utilities/useDates';
import { PreviewEntryDetails } from '../IndexReportTable';
import useTimelineStore from '../../../Timeline/useTimelineStore';
import CustomSelectMenu from '../../../../components/common/HTML_components/DropdownSelect/DropdownSelect';
import {
  useDocumentTags,
  useUpdateDocumentTags,
} from '../../../../components/DocumentScrolling/useTimelineEntryTags';
import useSources from '../../../Timeline/gql/useSources';
import { TimelineDetailsProps } from './DocumentPreviewer';
import { useGetPageByIdQuery } from '../../../../__generated__/graphql';
import { DocumentTagsObject, updateTimelineEntryStatus } from '../../../../api';
import { useIsFileProcessor } from '../../../AccountSettings/useFileProcessing';
import MultiSelectFreeFormDropdown from '../../../../components/common/HTML_components/MultiSelectDropdown/MultiSelectFreeFormDropdown';
import useBodyPartTags, {
  BodyPartTag,
  useBodyPartTagsForCase,
  useUpdateBodyPartTags,
} from '../../../../components/DocumentScrolling/useBodyPartTags';

type Option = {
  value: string;
  label: string;
};

type EditDocumentDetailsProps = {
  entryID: bigint;
  pageID: string;
  caseID: string;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  isFetchingEntryDetails?: boolean;
  setSelectedDate: (date: string) => void;
  isInTaggingTab?: boolean;
  refreshIndexReport?: () => void;
  updateIndexRowCache?: (rowId: string, updatedValues: any) => void;
  docStatus: string;
  refetchDocumentIDs?: () => void;
  handleNextPage?: () => void;
};

export function EditDocumentDetails({
  entryID,
  pageID,
  caseID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onUpdate,
  isFetchingEntryDetails = false,
  setSelectedDate,
  isInTaggingTab,
  refreshIndexReport,
  updateIndexRowCache,
  docStatus,
  refetchDocumentIDs,
  handleNextPage,
}: EditDocumentDetailsProps) {
  const { mutateAsync: updateBodyPartTags } = useUpdateBodyPartTags();
  const queryClient = useQueryClient();

  const [updatedEntryDetails, setUpdatedEntryDetails] =
    useState<PreviewEntryDetails>(currentEntryDetails);

  const [unknownDate, setUnknownDate] = useState(false);

  const [pageSelectedTags, setPageSelectedTags] = useState<Option[]>([]);

  const { data: documentTags } = useDocumentTags(entryID);
  const { data: bodyPartTags } = useBodyPartTags(entryID);

  const [selectedBodyPartTags, setSelectedBodyPartTags] = useState<Option[]>([]);

  const { loading, data: pageObject } = useGetPageByIdQuery({
    variables: {
      id: pageID,
    },
    skip: !pageID,
  });

  useEffect(() => {
    const pageTags = pageObject?.page?.tags;
    if (pageTags && documentTags) {
      const currentDocumentTagIDs = [
        ...documentTags.documentTypes.map((tag) => tag.tag_id),
        ...documentTags.specialties.map((tag) => tag.tag_id),
      ];
      setPageSelectedTags(
        pageTags
          .filter((pageTag) => !currentDocumentTagIDs.includes(pageTag.id))
          .map((tag) => ({ value: tag.id.toString(), label: tag.label })) ?? [],
      );
    }
  }, [pageObject, documentTags]);

  const allContentTags = useContentTypesAndSpecialities();
  const contentTagOptions = useMemo(() => {
    const tags = allContentTags.pageTagList.filter((tag) => tag.origin !== 'deprecated');
    return tags.map((tag) => ({
      value: String(tag.id),
      label: tag.label,
    }));
  }, [allContentTags.pageTagList]);

  const { data: bodyPartTagsForCase } = useBodyPartTagsForCase(caseID);

  const bodyPartTagOptions = useMemo(() => {
    const tags = bodyPartTagsForCase ?? [];
    return tags.map((tag) => ({
      value: String(tag.extraction_id),
      label: tag.value,
    }));
  }, [bodyPartTagsForCase]);

  useEffect(() => {
    if (bodyPartTags) {
      setSelectedBodyPartTags(
        bodyPartTags.map((tag: BodyPartTag) => ({
          value: String(tag.extraction_id),
          label: tag.value,
        })),
      );
    }
  }, [bodyPartTags]);

  const [bulkUpdatePageTags] = useBulkUpdatePageTags();

  const handleChangeLabel = useCallback(
    async (currentTags: Option[]) => {
      const isAdding = currentTags.length > (pageSelectedTags?.length || 0);
      const mappedTagIDs = currentTags.map((option) => +option.value);

      const validTags = allContentTags.pageTagList
        .filter((tag) => mappedTagIDs.includes(tag.id))
        .filter(Boolean);

      setPageSelectedTags(currentTags.filter(Boolean));

      await bulkUpdatePageTags({
        where: { id: +pageID },
        data: {
          tagsIds: mappedTagIDs,
          shouldInvalidateUnspecifiedTags: !isAdding,
        },
        newPagesState: {
          __typename: 'PageObject',
          id: +pageID,
          tags: validTags,
        },
      });
      if (refreshIndexReport) {
        refreshIndexReport();
      }
    },
    [allContentTags, pageID, bulkUpdatePageTags, pageSelectedTags, refreshIndexReport],
  );

  const handleStatusChange = async () => {
    try {
      await updateTimelineEntryStatus({
        caseId: caseID,
        caseFileId: currentEntryDetails?.file_id,
        entryId: entryID,
        currentStatus: docStatus,
      });
      toast.success('Status updated successfully');

      if (handleNextPage) {
        handleNextPage();
      }

      if (refetchDocumentIDs) {
        refetchDocumentIDs();
      }
    } catch (error) {
      console.error('Error occurred during status update:', error);
    }
  };

  const { hiddenEntities } = useTimelineStore(
    (state) => ({
      hiddenEntities: state.hiddenEntities,
    }),
    shallow,
  );

  const entities = useEntities(caseID, entryID);

  const filteredAuthors = useMemo(() => {
    return entities?.sortedAuthorsForCase.filter((author) => {
      return !hiddenEntities.includes(author.value) && !author.hidden;
    });
  }, [entities?.sortedAuthorsForCase, hiddenEntities]);

  const filteredOrgs = useMemo(() => {
    return entities?.sortedOrgsForCase.filter((org) => {
      return !hiddenEntities.includes(org.value) && !org.hidden;
    });
  }, [entities?.sortedOrgsForCase, hiddenEntities]);

  const handleUpdateDate = () => {
    if (updatedEntryDetails.entryDate !== currentEntryDetails.entryDate) {
      onUpdate(caseID, entryID, {
        date: updatedEntryDetails.entryDate,
      });
      setCurrentEntryDetails(updatedEntryDetails);
      setSelectedDate(updatedEntryDetails.entryDate);

      if (updateIndexRowCache) {
        updateIndexRowCache(String(entryID), {
          'document-date': updatedEntryDetails.entryDate,
        });
      }
    }
  };

  useEffect(() => {
    if (unknownDate) {
      handleUpdateDate();
      setUnknownDate(false);
    }
  }, [updatedEntryDetails.entryDate, unknownDate]);

  const handleSetUnknownDate = () => {
    setUpdatedEntryDetails((prevDetails) => ({
      ...prevDetails,
      entryDate: '1900-01-01',
    }));
    setUnknownDate(true);
    if (updateIndexRowCache) {
      updateIndexRowCache(String(entryID), {
        'document-date': '1900-01-01',
      });
    }
  };

  const handleBlurMonetaryTotal = async () => {
    if (updatedEntryDetails.monetary_total !== currentEntryDetails.monetary_total) {
      try {
        setCurrentEntryDetails(updatedEntryDetails);
        onUpdate(caseID, entryID, {
          monetary_total: updatedEntryDetails.monetary_total,
        });
        if (updateIndexRowCache) {
          updateIndexRowCache(String(entryID), {
            monetary_total: updatedEntryDetails.monetary_total,
          });
        }
      } catch (error) {
        toast.error(`Failed to update monetary total: ${error.message}`);
      }
    }
  };

  const handleUpdateAuthor = useCallback(async () => {
    if (!isEqual(updatedEntryDetails.author, currentEntryDetails.author)) {
      const previousDetails = { ...currentEntryDetails };

      setCurrentEntryDetails(updatedEntryDetails);

      try {
        await onUpdate(caseID, entryID, {
          author: updatedEntryDetails.author,
        });
      } catch (error) {
        setCurrentEntryDetails(previousDetails);
      }
    }
  }, [caseID, entryID, updatedEntryDetails, currentEntryDetails, onUpdate]);

  const handleUpdateOrganization = useCallback(async () => {
    if (!isEqual(updatedEntryDetails.organization, currentEntryDetails.organization)) {
      const previousDetails = { ...currentEntryDetails };

      setCurrentEntryDetails(updatedEntryDetails);

      try {
        await onUpdate(caseID, entryID, {
          organization: updatedEntryDetails.organization,
        });
      } catch (error) {
        setCurrentEntryDetails(previousDetails);
      }
    }
  }, [caseID, entryID, updatedEntryDetails, currentEntryDetails, onUpdate]);

  queryClient.invalidateQueries(['entities', caseID, entryID]);

  const handleUpdateBodyPartTags = useCallback(
    async (currentTags: Option[]) => {
      let needToRefetchBodyPartsTags = false;
      const formattedTags = currentTags.map((tag) => {
        if (!tag.value) {
          needToRefetchBodyPartsTags = true;
        }
        return {
          extraction_id: BigInt(tag.value ?? 0),
          value: tag.label,
        };
      });
      await updateBodyPartTags({
        caseID,
        documentID: entryID,
        tags: formattedTags,
      });
      setSelectedBodyPartTags(currentTags);
      if (refreshIndexReport) {
        refreshIndexReport();
      }
      if (needToRefetchBodyPartsTags) {
        queryClient.invalidateQueries(['documentBodyPartTags', entryID]);
      }
    },
    [entryID, selectedBodyPartTags],
  );

  useEffect(() => {
    if (!isEqual(currentEntryDetails, updatedEntryDetails)) {
      setUpdatedEntryDetails(currentEntryDetails);
    }
  }, [currentEntryDetails]);

  return (
    <div
      style={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '72%',
        overflowY: 'scroll',
        paddingBottom: isInTaggingTab ? '0' : '25rem',
      }}
      id="details-box"
    >
      <h2 style={{ textAlign: 'left', fontSize: '0.95rem', fontWeight: 500 }}>Document Details</h2>
      <div style={{ width: '85%' }} onBlur={handleUpdateAuthor}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
            fontWeight: 400,
            marginTop: '2.5px',
          }}
        >
          Author
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            entityName="author"
            valueID={updatedEntryDetails?.author.id ?? 0}
            optionsForCase={filteredAuthors}
            optionsForEntry={entities?.sortedAuthorsForEntry}
            allOptions={entities?.allAuthors}
            setValues={(e: { id: number | null; name: string | null; label: string | null }) =>
              setUpdatedEntryDetails({ ...updatedEntryDetails, author: e })
            }
            inputProps={undefined}
          />
        )}
      </div>
      <div style={{ width: '85%' }} onBlur={handleUpdateOrganization}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
            fontWeight: 400,
            marginTop: '-10px',
          }}
        >
          Organization
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <EntitySelect
            entityName="organization"
            valueID={updatedEntryDetails?.organization.id ?? 0}
            optionsForCase={filteredOrgs}
            optionsForEntry={entities?.sortedOrgsForEntry}
            allOptions={entities?.allOrgs}
            setValues={(e: { id: number | null; name: string | null; label: string | null }) =>
              setUpdatedEntryDetails({ ...updatedEntryDetails, organization: e })
            }
            inputProps={undefined}
          />
        )}
      </div>
      <div style={{ width: '85%' }} onBlur={handleUpdateDate}>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '-10px',
          }}
        >
          <h3
            style={{
              textAlign: 'left',
              fontSize: '0.8rem',
              fontWeight: 400,
              marginBottom: 0,
            }}
          >
            Date
          </h3>
          <Typography
            sx={{
              marginLeft: '-65%',
              fontSize: '12px',
              color: 'text.disabled',
              textAlign: 'center',
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.light',
              },
            }}
            onClick={handleSetUnknownDate}
          >
            Set as Unknown
          </Typography>
        </span>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                border: '0.5px solid rgb(208, 213, 221)',
                width: '100%',
                '& .MuiAutocomplete-inputRoot': {
                  border: 'none',
                  paddingY: 0,
                  paddingX: 0,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none', // Removes border
                  },
                  '&:hover fieldset': {
                    border: 'none', // Removes border on hover
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none', // Removes border when focused
                  },
                },
              }}
              format="YYYY-MM-DD"
              value={
                currentEntryDetails.entryDate !== '1900-01-01'
                  ? dayjs(currentEntryDetails.entryDate)
                  : ''
              }
              minDate={dayjs('1000-01-01')}
              maxDate={dayjs('2099-12-31')}
              onChange={(e) => {
                const newDate = dayjs(e).format('YYYY-MM-DD');
                if (isValidDate(newDate)) {
                  setUpdatedEntryDetails({
                    ...updatedEntryDetails,
                    entryDate: newDate,
                  });
                }
              }}
            />
          </LocalizationProvider>
        )}
      </div>
      <div style={{ width: '85%' }}>
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
            fontWeight: 400,
            marginBottom: '2.5px',
            marginTop: '8px',
          }}
        >
          Amount
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <TextField
            value={updatedEntryDetails.monetary_total}
            onChange={(e) =>
              setUpdatedEntryDetails({ ...updatedEntryDetails, monetary_total: e.target.value })
            }
            onBlur={handleBlurMonetaryTotal}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& .MuiTypography-root': { fontSize: 'small' } }}
                >
                  $
                </InputAdornment>
              ),
              style: { fontSize: '0.75rem' },
            }}
            sx={{
              width: '100%',
              minHeight: 'fit-content',
              overflowY: 'visible',
              alignContent: 'center',
              border: '0.5px solid rgb(208, 213, 221)',
              borderRadius: '10px',
              '& .MuiAutocomplete-inputRoot': { border: 'none', paddingY: 0, paddingX: 0 },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { border: 'none' },
                '&:hover fieldset': { border: 'none' },
                '&.Mui-focused fieldset': { border: 'none' },
              },
            }}
          />
        )}
      </div>
      <SplitTagsDetails
        caseID={caseID}
        entryID={entryID}
        currentEntryDetails={currentEntryDetails}
        setCurrentEntryDetails={setCurrentEntryDetails}
        onUpdate={onUpdate}
        documentTags={documentTags}
        isFetchingEntryDetails={isFetchingEntryDetails}
        refreshIndexReport={refreshIndexReport}
      />
      <div>
        <div style={{ width: '85%' }}>
          {isFetchingEntryDetails ? (
            <Skeleton
              variant="rectangular"
              width={320}
              height={38}
              sx={{ borderRadius: '10px', mb: '1.1rem' }}
            />
          ) : (
            <MultiSelectFreeFormDropdown
              options={bodyPartTagOptions ?? []}
              selectedValues={selectedBodyPartTags ?? []}
              onChange={(e) => handleUpdateBodyPartTags(e)}
              style={{
                width: '70%',
                fontSize: 'small',
              }}
              dropdownHeader="Body Part"
            />
          )}
        </div>
      </div>
      <h2 style={{ textAlign: 'left', fontSize: '0.95rem', fontWeight: 500, paddingTop: '25px' }}>
        Page Details
      </h2>
      <div style={{ width: '85%' }}>
        {loading ? (
          <CircularProgress size="1.4rem" sx={{ marginBottom: '0.7rem' }} />
        ) : (
          <MultiSelectDropdown
            options={contentTagOptions}
            selectedValues={pageSelectedTags ?? []}
            onChange={handleChangeLabel}
            shouldOpenByDefault={false}
            style={{
              width: '70%',
              fontSize: 'small',
            }}
            searchBar={false}
            dropdownHeader="Tags"
          />
        )}
        {['TAGGING', 'QA_REQUIRED'].includes(docStatus) && (
          <Button
            onClick={() => handleStatusChange()}
            variant="contained"
            color="primary"
            sx={{ marginTop: '0.5rem' }}
          >
            {docStatus === 'TAGGING' ? 'Confirm' : 'Approve'}
          </Button>
        )}
      </div>
    </div>
  );
}

type SplitTagsDetailsProps = {
  caseID: string;
  entryID: bigint;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  documentTags?: DocumentTagsObject;
  isFetchingEntryDetails: boolean;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  refreshIndexReport?: () => void;
};

function SplitTagsDetails({
  caseID,
  entryID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onUpdate,
  documentTags,
  isFetchingEntryDetails,
  refreshIndexReport,
}: SplitTagsDetailsProps) {
  const allContentTags = useContentTypesAndSpecialities();
  const contentTags = allContentTags?.content.map((tag) => {
    return { value: tag.tag_id, label: tag.name, deprecated: tag.deprecated };
  });
  const specialityTags = allContentTags?.specialities.map((tag) => {
    return { value: tag.tag_id, label: tag.name, deprecated: tag.deprecated };
  });

  const isFileProcessor = useIsFileProcessor();
  const contentTagsForCase = useMemo(() => {
    if (!contentTags) {
      return [];
    }
    return contentTags
      .filter(
        (tag) =>
          !documentTags?.documentTypes
            .map((tag) => String(tag.tag_id))
            .includes(String(tag.value)) && !tag.deprecated,
      )
      .map((tag) => {
        return { value: String(tag.value), label: tag.label };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [documentTags, contentTags]);

  const specialityTagsForCase = useMemo(() => {
    if (!specialityTags) {
      return [];
    }
    return specialityTags
      .filter(
        (tag) =>
          !documentTags?.specialties.map((tag) => String(tag.tag_id)).includes(String(tag.value)) &&
          !tag.deprecated,
      )
      .map((tag) => {
        return { value: String(tag.value), label: tag.label };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [documentTags, specialityTags]);

  const [documentType, setDocumentType] = useState<any[]>(
    documentTags?.documentTypes.map((tag) => {
      return { value: tag.tag_id, label: tag.name };
    }) ?? [],
  );
  const [speciality, setSpeciality] = useState<any[]>(
    documentTags?.specialties.map((tag) => {
      return { value: tag.tag_id, label: tag.name };
    }) ?? [],
  );

  useEffect(() => {
    if (documentTags) {
      setDocumentType(
        documentTags?.documentTypes.map((tag) => {
          return { value: tag.tag_id, label: tag.name };
        }) ?? [],
      );
      setSpeciality(
        documentTags?.specialties.map((tag) => {
          return { value: tag.tag_id, label: tag.name };
        }) ?? [],
      );
    }
  }, [documentTags]);

  const sources = useSources();

  const sourceOptions = sources
    ?.filter((source) => !source.deprecated)
    .map((sourceName) => ({
      value: sourceName.tag_id,
      label: sourceName.name,
    }));
  const handleUpdateSource = (newSource) => {
    if (newSource.value !== currentEntryDetails.sourceID) {
      onUpdate(caseID, entryID, {
        source: newSource.value,
      });
      setCurrentEntryDetails({ ...currentEntryDetails, sourceID: newSource.value });
      if (refreshIndexReport) {
        refreshIndexReport();
      }
    }
  };

  const { mutateAsync: updateDocumentTagsWithType } = useUpdateDocumentTags();

  const handleUpdateDocumentType = async (tags: Option[]) => {
    setDocumentType(tags);
    if (isEqual(documentTags?.documentTypes, tags)) {
      return;
    }

    const tagType = 'Content';
    await updateDocumentTagsWithType({
      entryID: entryID,
      tags: tags.map((tag) => {
        return { tag_id: tag.value, name: tag.label, category: 'Content' };
      }),
      tagType,
    });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
  };

  const handleUpdateSpeciality = async (tags: Option[]) => {
    setSpeciality(tags);
    if (isEqual(documentTags?.specialties, tags)) {
      return;
    }

    const tagType = 'Specialist';
    await updateDocumentTagsWithType({
      entryID: entryID,
      tags: tags.map((tag) => {
        return { tag_id: tag.value, name: tag.label, category: 'Specialist' };
      }),
      tagType,
    });
    if (refreshIndexReport) {
      refreshIndexReport();
    }
  };

  return (
    <>
      <div id="source">
        <h3
          style={{
            textAlign: 'left',
            fontSize: '0.8rem',
            fontWeight: 400,
            marginBottom: '2.5px',
            marginTop: '5px',
          }}
        >
          Source Type
        </h3>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <CustomSelectMenu
            options={sourceOptions}
            onChange={(e) => {
              handleUpdateSource(e);
            }}
            currentOption={{
              value: currentEntryDetails.sourceID,
              label:
                sourceOptions?.find(
                  (option) => option.value === Number(currentEntryDetails.sourceID),
                )?.label ?? null,
            }}
            onCommit={() => {}}
          />
        )}
      </div>
      <div style={{ width: '85%' }}>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <MultiSelectDropdown
            options={contentTagsForCase}
            selectedValues={documentType}
            onChange={handleUpdateDocumentType}
            shouldOpenByDefault={false}
            style={{
              width: '70%',
              fontSize: 'small',
            }}
            dropdownHeader="Type"
          />
        )}
      </div>
      <div style={{ width: '85%' }}>
        {isFetchingEntryDetails ? (
          <Skeleton
            variant="rectangular"
            width={320}
            height={38}
            sx={{ borderRadius: '10px', mb: '1.1rem' }}
          />
        ) : (
          <MultiSelectDropdown
            options={specialityTagsForCase}
            selectedValues={speciality}
            onChange={handleUpdateSpeciality}
            shouldOpenByDefault={false}
            style={{
              width: '70%',
              fontSize: 'small',
            }}
            dropdownHeader="Specialty"
          />
        )}
      </div>
    </>
  );
}
